import http from '@/http';

export function getDropDownElementsByTypeApi(type){
    return http.post("/settings/liste_deroulante_details/getByType", {
        type: type
    });
}

export function getHseCriteriasFromTypeApi(type){
    return http.post("/system/settings/getHseCriteriasByType", {
        type: type
    });
}

export function getContractInvoicesListApi(contractId){
    return http.post("/invoicing/getContractInvoicesList", {
        contractId: contractId,
    })
}

export function getContractSupplierInvoicesListApi(contractId){
    return http.post("/supplier_invoicing/getContractInvoicesList", {
        contractId: contractId,
    })
}

export function fetchPaymentStatusList(){
    return http.post("/system/misc/fetchPaymentStatusList")
}

export function fetchContractsList(){
    return this.$http.post("/contracts/contracts/list")
}

export function fetchSettings(){
    return http.post("/system/settings/fetchAll")
}

export function fetchLifebasesApi(){
    return http.post("/base/lifebases/list")
}

export function fetchSectorsApi(){
    return http.post("/infrastructure/sectors/list")
}

////Referentiels
export function fetchCompanyListApi(){
    return http.post("/referentiel/company_visitor/list")
}

export function fetchVisitorListByCompanyApi(company_id){
    return http.post("/referentiel/visitors_company/listByCompany",{company_id : company_id})
}

export function fetchVisitorListApi(){
    return http.post("/referentiel/visitors_company/list")
}

export function fetchPrestatairesListApi(){
    return http.post("/referentiel/employees_prestataire/list")
}
export function fetchEmployesListApi(){
    return http.post("/referentiel/employees/list")
}
export function fetchContratTypesListApi(){
    return http.post("/base/contratType/list")
}
export function fetchSupplierListApi(){
    return http.post("/contracts/suppliers/list")
}

export function fetchPaginateSuppliersListApi(){
    return http.get("/contracts/suppliers/paginate")
}

export function fetchPenalityTypesApi(){
    return http.post("/contracts/penalty_types/list")
}

export function fetchProductsCategoriesListApi(){
    return http.post("/system/misc/fetchProductsCategories")
}
export function fetchAllPermissionsTable(){
    return http.post("/system/roles/lis")
}
export function fetchProductsUomsListApi(){
    return http.post("/system/stock/warehouses/list")
}
export function fetchWarehousesListApi(){
    return http.post("/system/stock/warehouses/list")
}
export function fetchGarantieTypesApi(){
    return http.post("/contracts/warranty_types/list")
}
export function fetchContractStdServicesApi(){
    return http.post("/contracts/contracts/getStdServices")
}

export function fetchContractsListApi(status = ''){
    return http.post("/contracts/contracts/list", {
        status: status,
    })
}

export function fetchOperationsListApi(){
    return http.post("/operation/list")
}
export function fetchSuppliersListApi(){
    return http.post("/contracts/suppliers/list")
}
export function fetchEspVertTypeApi(){
    return http.post("/base/escpaceVert/TypeEscpaceVert/list")
}
export function fetchHebergementTypeApi(){
    return http.post("/base/hebergement/TypeHebergement/list")
}
export function fetchServiceTypeApi(){
    return http.post("/base/restauration/service/list")
}
export function fetchRepasTypeApi(){
    return http.post("/base/restauration/typeRepas/list")
}
export function fetchLieuRepasApi(){
    return http.post("/base/restauration/LieuRepas/list")
}
export function fetchPeriodRepasApi(){
    return http.post("/base/restauration/PeriodeRepas/list")
}

export function fetchMaintTypeApi(){
    return http.post("/base/maintenance/TypeMaintenance/list")
}
export function fetchOpsTypeApi(){
    return http.post("/base/operations3D/TypeOperations3D/list")
}
export function fetchDiversTypeApi(){
    return http.post("/base/diverses/TypeDiverses/list")
}
export function fetchUsersListApi(){
    return http.post("/system/users/list")
}

export function fetchBookingBeneficiatyTypesApi(){
    return http.post("/system/misc/fetchBookingBeneficiatyTypes")
}

export function fetchUserStructuresTypesApi(){
    return http.post("/system/misc/fetchUserStructuresTypes")
}

export function fetchHierarchysListApi(){
    return http.post("/organisation/hierarchy/direction/getHierasList");
}

export function fetchDirectionsListApi(){
    return http.get("/organisation/hierarchy/direction/getDirectionsList");
}

export function fetchDivisionsListApi(){
    return http.get("/organisation/hierarchy/division/getDivisionsList");
}

export function fetchDepartmentsListApi(){
    return http.get('/organisation/hierarchy/departement/getDepartementsList');
}

export function fetchServicesListApi(){
    return http.get("/organisation/hierarchy/service/getServicesList");
}

export function fetchSectionsListApi(){
    return http.get("/organisation/hierarchy/section/getSectionsList");
}

export function fetchEmployeesListApi(data){
    return http.post("/base/employees/list", data);
}

export function fetchSingleEmployeeDataApi(uuid){
    return http.post('base/employees/getSingleEmployeeDetails/' + uuid);
}


export function fetchEmployeesPrestataireListApi(data){
    return http.post("/base/employees_prestataire/list", data);
}

export function fetchSingleEmployeePrestataireDataApi(uuid){
    return http.post('base/employees_prestataire/getSingleEmployeeDetails/' + uuid);
}

export function fetchRolesListApi(){
    return http.post("/system/roles/list")
}

export function fetchTakeAwayMealRequestDestinationsApi(){
    return http.post("/system/misc/fetchTakeAwayMealRequestDestinations")
}

export function fetchTakeAwayMealRequestExternalDestinationsApi(){
    return http.post("/system/misc/fetchTakeAwayMealRequestExternalDestinations")
}

export function fetchMealsTypesApi(){
    return http.post("/system/misc/fetchMealsTypes");
}

export function fetchProductsUomsApi(){
    return http.post("/system/misc/fetchProductsUoms");
}

export function fetchProductsCategoriesApi(){
    return http.post("/system/misc/fetchProductsCategories");
}

export function fetchWarehousesApi(){
    return http.post("/system/misc/fetchWarehouses");
}

export function fetchProductsApi(){
    return http.post('stock/products/list');
}

export function fetchProductsWithCategoryListApi(categoryId){
    return http.post('stock/products/list_per_category', {
        categoryId: categoryId
    });
}

export function fetchEquipmentsWithCategoryListApi(categoryId){
    return http.post('infrastructure/equipments/list_per_category', {
        categoryId: categoryId
    });
}

export function fetchProductDetailsApi(uuid){
    return http.post('stock/products/getSingleProductDetails/' + uuid);
}

export function fetchIdentityDocTypesApi(){
    return http.post("/system/misc/fetchIdentityDocTypes");
}

export function fetchSupportTypesApi(){
    return http.post("/system/misc/fetchSupportTypes");
}

export function fetchProjectsApi(){
    return http.post("/misc/projects/list");
}

export function fetchRoomsTypesApi(){
    return http.post("/infrastructure/room_types/list");
}

export function fetchMaintenanceTypesApi(){
    return http.post("/settings/maintenance/list");
}
export function fetchPersonnelTypesApi(){
    return http.post("/settings/personnel/list");
}

export function fetchEquipmentsTypesApi(){
    return http.post("/infrastructure/equipment_types/list");
}

export function fetchEquipmentsCategsApi(){
    return http.post("/infrastructure/equipment_categories/list");
}

export function fetchEquipmentsBrandsApi(){
    return http.post("/infrastructure/equipment_brands/list");
}

export function fetchRoomsListApi(){
    return http.post("/infrastructure/rooms/list");
}

export function fetchRoomsByBlocListApi(blocId){
    return http.post("/infrastructure/rooms/getByBloc", {
        blocId: blocId,
    });
}

export function fetchVisitorsListApi(){
    return http.post("/ops/requests/visitors/list");
}

export function fetchEquipmentsApi(){
    return http.post("/infrastructure/equipments/list");
}

export function fetchSingleEquipmentDetailsApi(uuid){
    return http.post("/infrastructure/equipments/getSingleEquipmentDetails/"+uuid);
}

export function loadControlCriteriasApi(type){
    return http.post("/ops/controls/getControlCriterias", {
        type: type
    });
}