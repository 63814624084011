<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import roomsTable from './components/roomsTable';
import equipement from './components/equipement';
import { required, maxLength } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import { getDropDownElementsByTypeApi } from "@/api/common";
// import SideButtons from '../../../../../components/side-buttons.vue';

export default {
  page: {
    title: "Chambres",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, roomsTable, equipement , Multiselect /* ,SideButtons */},
  data() {
    return {
      reloadRooms:undefined,
      tableData: [],
      blocs: [],
      equipments: [],
      selectedEquipements:[],
      equipmentStatus: [
        {
          id: 'new',
          name: 'Nouveau'
        },
        {
          id: 'used',
          name: 'Utilisé'
        },
      ],
      roomTypes: [],
      title: "Chambres",
      rooms: [],
      selectedRows: null,
      selectedR : null,
      items: [
        {
          text: "Master Data",
          to: { name: 'master_data' }
        },
        {
          text: "Referentiels",
          to: { name: 'master_data.referentiels' }
        },
        {
          text: "Chambres",
          active: true
        }
      ],
      /* filter: null,
      filterOn: [], */
     
      fields: [
        { key: "number", sortable: true },
        { key: "bloc", sortable: true },
        { key: "type", sortable: true },
        { key: "status", sortable: true },
      ],
      lifeBases :[],
      lbl :[],
      roomForm: {
        id :"",
        lifebase_id: "",
        roomNumber: "",
        roomBloc: "",
        roomType: "",
        phone : "",
        // roomPhone: "",
      },
      roomFormRest: {
        id :"",
        lifebase_id: "",
        roomNumber: "",
        roomBloc: "",
        roomType: "",
        phone : "",
        // roomPhone: "",
      },
      SelectedCategory:undefined,
      lastSelect :null,
      disabled : true,
      canDelte : false,
      disabledAdd : false,
      disabledUpdate : false,
      disabledCancel : true,
      disabledDelete : true,
      add : false,
      edit : false,
      destroy : false,
      send : false,
      have : false,
      dp : 1,
      submitted: true,
    };
  },
  validations: {
    roomForm: {
      roomNumber: { required, maxLength: maxLength(64) },
      roomBloc: { required },
      roomType: { required },
      lifebase_id  :{required}
    },
  },
  computed: {

    rows() {
      return this.tableData.length;
    }
  },
  mounted() {
    this.lastSelect = this.roomForm;
    this.totalRows  = this.tableData.length;

    this.getLifeBases();
  //  this.refreshRoomsTable();
  },
  created: function() {
    this.getRoomsTypes();
  },
  watch :{
    add(){
      this.addBtn()
    },
    duplicate(){
      this.duplicateBtn()
    },
    edit(){
      this.editBtn()
    },
    destroy(){
      this.deleteRoom()
    },
    SelectedCategory:{
        handler(val){
          this.roomForm.roomBloc = val;

        }
    },
  },
  methods: {
    
    addBtn(){
      this.disabled       = false;
      this.disabledAdd    = true;
      this.disabledEdit   = true;
      this.disabledCancel = false;
      this.disabledDelete = true;
      this.disabledUpdate = false;
      this.clearForms();
    },
    duplicateBtn(){
      this.disabled       = false;
      this.disabledAdd    = true;
      this.roomForm.uuid  = "";
      this.disabledCancel = true;
      this.disabledDelete = true;
      this.disabledUpdate = false;
    },
    editBtn(){
      this.disabledAdd    = false;
      this.disabledUpdate = true;
      this.disabled       = false;
      this.disabledCancel = false;
      this.disabledDelete = true;

    },
    cancelBtn(){
      this.disabled       = true;
      this.disabledAdd    = false;
      this.disabledUpdate = false;
      this.disabledEdit   = true;
      this.disabledCancel = true;
      if(this.lastSelect != null){
        this.roomForm.roomBloc = this.lastSelect ;
        this.disabledDelete = true;

      }
    },
    getLifeBases(){
      let loader = this.$loading.show({
        color: '#000000',
        loader: 'spinner',
        width: 64,
        height: 64,
        backgroundColor: '#ffffff',
        opacity: 0.5,
        zIndex: 999,
      })
      var _this = this
      this.$http.get('/base/lifebases/index')
      .then(response => {
          _this.lbl = response.data;
          loader.hide();
      })
      .catch(error => console.log(error))
    },

    refreshRoomsTable(){
    this.$http.post('/infrastructure/rooms/list')
          .then(res => (this.rooms = res.data.original.list))
          .catch(() => {
              // error.response.status Check status code
              
          }).finally(() => {
              //Perform action in always
          });
    },
    /* onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }, */
    
    getBlocs() {
      var _this = this;
      let loader = this.$loading.show({
        color: '#000000',
        loader: 'spinner',
        width: 64,
        height: 64,
        backgroundColor: '#ffffff',
        opacity: 0.5,
        zIndex: 999,
      })
      this.$http
        .post("/infrastructure/blocs/list",{'life_base_id':this.roomForm.lifebase_id})
        .then((res) => {
          loader.hide();
          _this.blocs = res.data.original.list;
        })
        .catch((error) => {
          loader.hide();
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {
          loader.hide();
        });
    },

    getRoomsTypes() {
      var _this = this;
      getDropDownElementsByTypeApi("hosting_room_classification")
        .then((res) => {
          _this.roomTypes = res.data.original.list;
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
    },
    selectedRooms(selectedRooms){
        this.clearForms()
        this.disabledAdd    = false;
        this.disabledDelete = true;
        this.disabledEdit   = true;
        this.disabledCancel = true;

      if(selectedRooms.length> 0 &&  undefined != selectedRooms[0]){
        this.disabled       = true;
        this.disabledAdd    = false;
        this.disabledDelete = false;
        this.disabledEdit   = false;
        this.disabledCancel = false;
        this.roomForm.id          = selectedRooms[0].id;
        this.roomForm.uuid        = selectedRooms[0].uuid;
        this.roomForm.lifebase_id = selectedRooms[0].lifebase_id;
        this.getBlocs()
        this.roomForm.roomNumber = selectedRooms[0].number;
        this.roomForm.phone      = selectedRooms[0].phone;
        this.roomForm.roomBloc   = this.blocs.filter((x) => (x.id == selectedRooms[0].bloc))[0]
        this.roomForm.roomType   = selectedRooms[0].roomType;        
        this.roomForm.notes      = selectedRooms[0].notes;        
        this.lastSelect          = this.roomForm;
        this.selectedEquipements = selectedRooms[0].equipments
        this.selectedR           = selectedRooms[0];
      }
    },
    clearForms(){
      this.roomForm.id          = "";
      this.roomForm.lifebase_id = "";
      this.roomForm.roomNumber  = "";
      this.roomForm.roomBloc    = "";
      this.roomForm.roomType    = "";
      this.roomForm.notes       = "";
      this.SelectedCategory     = "";
      this.roomForm.phone       = "";
    },

    formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.submitted = false;
        let loader = this.$loading.show({
          color: '#000000',
          loader: 'spinner',
          width: 64,
          height: 64,
          backgroundColor: '#ffffff',
          opacity: 0.5,
          zIndex: 999,
        })
        this.$http
          .post("/infrastructure/rooms/store", this.roomForm)

          .then((res) => {
            var status = res.data.original.status;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                // Swal.fire("Ajouter!", res.data.original.msg, "success");
                this.reloadRooms = true
                this.disabled       = true;
                this.clearForms();
                this.disabledAdd    = false;
                this.disabledDelete = true;
                this.disabledEdit   = true;
                this.disabledCancel = true;
              break;
              case 500:
                this.$toast.error(res.data.original.msg);
                // Swal.fire("Erreur!", res.data.original.msg, "error");
                break;
            }
            loader.hide();
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },
    
    deleteRoom() {
      Swal.fire({
        title: "Êtes-vous sûr de supprimer : " + this.roomForm.roomNumber + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui!",
      }).then((result) => {
        if (result.value) {
          let loader = this.$loading.show({
            color: '#000000',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
          })
          this.$http
            .post("/infrastructure/rooms/delete/" + this.roomForm.uuid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                this.$toast.success(res.data.original.msg);
                  this.reloadRooms = true
                  this.clearForms();
                  break;

                case 500:
                this.$toast.error(res.data.original.msg);
                  break;
              }
              loader.hide();
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },

    updateRoom() {
      this.submitted = true;
      this.$v.$touch();
      if(!this.$v.$invalid /* && this._validateRoomEquipmentData() */){
        let loader = this.$loading.show({
          color: '#000000',
          loader: 'spinner',
          width: 64,
          height: 64,
          backgroundColor: '#ffffff',
          opacity: 0.5,
          zIndex: 999,
        })
      this.$http
        .post("/infrastructure/rooms/update", this.roomForm)
        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
                this.$toast.success(res.data.original.msg);
              // Swal.fire("Modifier!", res.data.original.msg, "success");
                this.reloadRooms = true
                this.disabled    = true;
                this.disabledAdd = true;
                this.disabledUpdate = true;
                this.disabledCancel = false;
                this.disabledDelete = true;
              break;
            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
            loader.hide();
        })
        .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
        })
        .finally(() => {});
      }
    },
  }
};
</script>
<style >
  .finish-button{
    background-color:#43A047 !important;
    border-color: #43A047 !important;
  }
    @media screen and (-webkit-min-device-pixel-ratio:0) {

    /*Chrome CSS here*/
    #StickyH {
    position: fixed;
    right: 0;
    top: 50%;
    width: 4em;
    margin-top: -2.5em;
    z-index: 999;
    list-style: none;
  }
    
}

@supports (-moz-appearance:none){ 
  #StickyH {
    position: fixed;
    right: 0;
    top: 50%;
    width: 1em;
    margin-top: -2.5em;
    z-index: 999;
    list-style: none;
  }
}
  .rounded-pillH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 50% !important;
  }
  .btn-addH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #044A72 ;
  }
  .btn-duplicateH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #8FC412 ;
  }
  .btn-editH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #FFB236 ;
  }
  .btn-deleteH #dropdown-dropleft__BV_toggle_{
     border-radius: 50% !important;
  }
  .btn-deleteH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #D61337;
  }
  .btn-uploadH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #2A5769 ;
  }
  .btn-downloadH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #21BF92;
  }
  .btn-cancelH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #B8CCD7 ;
  }
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    
    <rooms-table :reloadRooms="reloadRooms" :lifebaseList="lbl" :roomType="roomTypes" @selectedRooms="selectedRooms" @reloadRoomsDone="reloadRooms = $event" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-tabs content-class="p-3 text-muted" >     
              <b-tab active class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block" >Infos Générales</span>
                </template>
                <!--   <SideButtons
                  :select="disabledAdd"  :DP="dp" :canEdit="disabledDelete" :save="send" :ODS="false"   @add="add = $event" @duplicate="duplicate = $event" @edit=" edit = $event"  @delete=" destroy = $event" />
                 -->
                  <ul id="StickyH" >
                    <li>
                      <button type="button" :disabled="disabledAdd && !$can('create_hosting_room')" @click="addBtn" class="btn btn-addH rounded-pillH float-right mr-1 mt-1">
                      <i class="fas fa-plus text-white"></i>
                      </button>
                    </li>
                    <li>
                      <button type="button" :disabled="disabledDelete && !$can('update_hosting_room')" @click="editBtn" class="btn btn-editH rounded-pillH float-right mr-1 mt-1">
                        <i class="fas fa-pencil-alt text-white" style=""></i>
                      </button>
                    </li>
                    <li>
                      <button type="button" @click="deleteRoom" :disabled="disabledDelete && !$can('delete_hosting_room')" class="btn btn-deleteH rounded-pillH float-right mr-1 mt-1">
                          <i class="far fa-trash-alt text-white"></i>
                      </button>
                    </li>
                    <li>
                      <button type="button"  class="d-none btn btn-uploadH rounded-pillH float-right mr-1 mt-1">
                      <i class="fas fa-file-upload  text-white"></i>
                      </button>
                    </li>
                    <li>
                      <button type="button"  class="d-none btn btn-downloadH rounded-pillH float-right mr-1 mt-1">
                      <i class="fas fa-file-download text-white"></i>
                      </button>
                    </li>
                    <li>
                      <button type="button"  class="d-none btn btn-printH rounded-pillH float-right mr-1 mt-1">
                      <i class="fas fa-print"></i>
                      </button>
                    </li>
                  </ul>
                  <h4 class="card-title  pb-2 col-12">
                    Chambres Details
                  </h4>
                  <fieldset :disabled="disabled">
                    <div class="col-12 row">
                      <div class="col-xs-3 col-md  ">
                        <div class="form-group">
                          <label for="validationCustom02">Base De Vie</label>
                          <select @change="getBlocs()"
                            v-model="roomForm.lifebase_id"
                            :class="{'is-invalid': submitted && $v.roomForm.lifebase_id.$error,
                                    'disabledMS': disabled,}" class="custom-select">
                            <option selected disabled>Sélectionner une base </option>
                            <option
                              v-for="lifeBase in lbl"
                              v-bind:value="lifeBase.id"
                              v-bind:key="lifeBase.id">
                              {{ lifeBase.name }}
                            </option>
                          </select>
                          <div v-if="submitted && $v.roomForm.lifebase_id.$error" class="invalid-feedback">
                            <span v-if="!$v.roomForm.lifebase_id.required">Le Base De Vie est obligatoire.</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-xs-3 col-md  ">
                        <div class="form-group">
                          <label for="validationCustom02">Bloc</label>
                          <multiselect
                            :select-label="''"
                            v-model="roomForm.roomBloc"
                            :searchable="true"
                            track-by="id"
                            label="name"
                            :options="blocs"
                            placeholder="Selectionner"
                            :allow-empty="false"
                            :class="{'disabledMS': disabled,}"
                          >
                            <template slot="singleMealType" slot-scope="{ eqp }" >{{ eqp.name }}</template >
                          </multiselect>
                          <!-- <select v-model="SelectedCategory" :class="{'is-invalid': submitted && $v.roomForm.roomBloc.$error,'disabledMS': disabled,}" class="custom-select">
                            <option selected>Sélectionner une classe</option>
                            <option v-for="bloc in blocs" v-bind:value="bloc.id" v-bind:key="bloc.id">
                              {{ bloc.name }}
                            </option>
                          </select> -->
                          <div v-if="submitted && $v.roomForm.roomBloc.$error" class="invalid-feedback">
                            <span v-if="!$v.roomForm.roomBloc.required" >Le bloc est obligatoire.</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-xs-3 col-md  ">
                        <div class="form-group">
                          <label for="validationCustom02">Type</label>
                          <select
                            v-model="roomForm.roomType"
                            :class="{'is-invalid': submitted && $v.roomForm.roomType.$error, 'disabledMS': disabled,}"
                            class="custom-select">
                            <option selected></option>
                            <option v-for="type in roomTypes" v-bind:value="type.id" v-bind:key="type.id">
                              {{ type.designation }}
                            </option>
                          </select>
                          <div v-if="submitted && $v.roomForm.roomType.$error"  class="invalid-feedback" >
                            <span v-if="!$v.roomForm.roomType.required" >Le type est obligatoire.</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-xs-3 col-md  ">
                        <div class="form-group">
                          <label for="deptName"><span v-if="disabledAdd">Nombre de chambres </span><span v-else>Numero de chambre</span></label>
                          <input id="deptName" v-model="roomForm.roomNumber" type="number" class="form-control" placeholder="" value=""
                            :class="{'is-invalid':submitted && $v.roomForm.roomNumber.$error,'disabledMS': disabled,}"/>
                          <div v-if="submitted && $v.roomForm.roomNumber.$error" class="invalid-feedback" >
                            <span v-if="!$v.roomForm.roomNumber.required">Le numéro de chambre est obligatoire.</span>
                            <span v-if="!$v.roomForm.roomNumber.maxLength">La taille du champs est limitée a 64 charactères.</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-xs-3 col-md  " v-if="!disabledAdd">
                        <div class="form-group">
                          <label for="phone"><span >Téléphone</span></label>
                          <input id="phone" v-model="roomForm.phone" type="text" class="form-control" placeholder="" value=""
                            :class="{'disabledMS': disabled,}"/>

                        </div>
                      </div>
                    </div>
                    <div class="col-12 row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="formrow-inputCity">Commentaires</label>
                          <textarea
                            class="form-control"
                            v-model="roomForm.notes"
                            cols="30"
                            rows="1"
                            :class="{'disabledMS': disabled,}"
                          ></textarea>
                        </div>
                      </div>
                    </div> 
                    <div class="button-items float-right ">
                      <div class="col-md-12" v-show="!disabled"> 
                        <button type="button"  @click="cancelBtn" class="btn btn-light btn-label float-right ml-2">
                          <i class="fas fa-times-circle text-danger label-icon"></i> Annuler
                        </button>
                        
                      <button
                          class="btn btn-primary float-right btn-label"
                          type="button"
                          @click="formSubmit"
                          v-show="disabledAdd && $can('create_hosting_room')"
                        >
                          <i class="far fa-save label-icon "></i> Enregistrer
                        </button>
                        <button
                          class="btn btn-primary float-right btn-label"
                          type="button"
                          @click="updateRoom"
                          v-show="disabledUpdate && $can('update_hosting_room')"
                        >
                          <i class="far fa-save label-icon "></i> Enregistrer
                        </button>
                        
                      </div>
                        
                    </div>
                  </fieldset>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block" @click="tableindex = 1"
                    >Fiche d'inventaire</span>
                </template>
                <equipement  :selectedEquipements="selectedEquipements" :selectedRoom="selectedR"/>
              </b-tab>

                    
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    
  </Layout>
</template>